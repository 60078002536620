import React, {useContext} from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import * as Icon from 'react-bootstrap-icons';

const Menu: React.FC = () => {
    const authContext = useContext(AuthContext);

    return (
       
        <Navbar bg="dark" variant="dark" expand="md" className="py-1">
          <Container fluid={true} >
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/">Principal</Nav.Link>
                
                <NavDropdown title="Manifesto" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/manifesto"> MDF-e</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/condutor"> Condutores</NavDropdown.Item>
                  

                  {/* adicione um separador */}
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/pais"> Países</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/estado"> Estados</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/municipio"> Municipios</NavDropdown.Item>

                  

                </NavDropdown>
                <NavDropdown title="Segurança" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/profile"> Perfis de usuário</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/users"> Usuários</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/permissions"> Permissões</NavDropdown.Item>

                </NavDropdown>
              </Nav>

              <Nav className="ms-auto" >
                <NavDropdown  title={<Icon.BuildingFillCheck />}  id="basic-nav-dropdown" align="end" >
                    
                    {authContext?.empresas?.map((empresa: any) => (
                        
                        <NavDropdown.Item className=""
                          key={empresa.id} onClick={() => authContext.changeEmpresa(empresa.id)}  > {
                            empresa.id === authContext?.empresa ? <FontAwesomeIcon icon={faCheck} /> : ''
                        } {empresa.nome}</NavDropdown.Item>
                    ))}
                  
                </NavDropdown>
              </Nav>


              <Nav className="ms-2" >
                <NavDropdown title={authContext?.user} id="basic-nav-dropdown" align="end">
                    {authContext?.isAuthenticated && <NavDropdown.Item  onClick={authContext.logout}><FontAwesomeIcon  className="text-danger" icon={faRightFromBracket} />  Sair</NavDropdown.Item> }
                </NavDropdown>
              </Nav>

            </Navbar.Collapse>
          </Container>
        </Navbar>
    );
}

export default Menu;