import api from "../api/api";   

class AuxService {
    
    async getPermissions() {
        return await api.get(`permissions_disponiveis`).then(response => response.data);
    }
    
}

const auxService = new AuxService();

export default auxService;