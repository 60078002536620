import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../api/api";
import api from "../api/api";
import { iAuthInterface } from "../interfaces/AuthInterface";
import { UserInterface } from "../interfaces/UserInterface";

export const AuthContext = createContext<iAuthInterface | null >(null)

interface EmpresaInterface {
    id: string;
    nome: string;
    cnpj: string;
}

interface PermissionInterface {
    name: string;
}

const initialFieldsError = {
    email: [],
    password: [],
}

const initialUser = {
    id: "",
    email: "",
    empresa_logada: 0,
    is_staff: false,
    is_superuser: false,
    name: "",
    tenant_id: 0,
    permissions: [],
    active: 0,
    empresas: []
}

export const AuthProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState('')
    const [userLogado, setUserLogado] = useState<UserInterface>(initialUser);
    const navigate = useNavigate();
    const [errors, setErrors] = useState(initialFieldsError);
    const [showError, setShowError] = useState(false);
    const [showMessageError, setShowMessageError] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [empresas, setEmpresas] = useState<EmpresaInterface[]>([]);
    
    const changeShowError = (show: boolean) => {
        setShowError(false);
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        
        if (token) {
            api.get('user').then((res) => {
                setIsAuthenticated(true);
                setUser(user.user.name);
                setEmpresas(user.user.empresas);
                setEmpresa(user.user.empresa_logada);
                setUserLogado(user.user);
                navigate('/');
            }).catch((error) => {
                navigate('/login');
            });
        } else {
            navigate('/login');
        }
    }, []);

    function checkPermission(permission: string): boolean {
        return userLogado.permissions.filter((perm: string) => perm === permission).length > 0
    }

    const login = async (email: string, password: string) => {
        
        try {
            setErrors(initialFieldsError);
            
            return await axios.post(`${BASE_URL}login`, {
                email: email,
                password: password
            }).then((res) => {
                
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('user', JSON.stringify(res.data));
                
                setEmpresas(res.data.user.empresas);
                setEmpresa(res.data.user.empresa_logada);
                setUser(res.data.user.name);
                setUserLogado(res.data.user);
                setIsAuthenticated(true);
                localStorage.setItem('empresa', res.data.user.empresa_logada);
                navigate('/');
            }).catch((error) => {

                 if (error.response.status === 422) {
                     setErrors(error.response.data)
                 }
                 if (error.response.status === 401) {
                     setShowError(true)
                     setShowMessageError(error.response.data.message)
                 }
               
            });
            
        } catch (error) {
            navigate('/login');
        }
    };

    const changeEmpresa = (empresa: string): void => {
        navigate('/');
    }

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('empresa');
        setIsAuthenticated(false);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={
                { 
                    setShowError,
                    setShowMessageError,
                    empresa, 
                    empresas, 
                    isAuthenticated, 
                    user, 
                    errors, 
                    showError, 
                    changeShowError, 
                    showMessageError, 
                    login, 
                    logout,
                    changeEmpresa,
                    userLogado,
                    checkPermission
                }
            }>
            {children}
        </AuthContext.Provider>
    );
}
