import { useEffect } from 'react';
import { BASE_URL } from '../api/api';
import { useNavigate } from "react-router-dom";
import { JAlerta } from '../components/Components';
import { AuthContext } from '../contexts/AuthContext';
import { useContext } from 'react';

export default function Home() {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
     useEffect(() => {
        const token = localStorage.getItem('token');
   
        if (token === null) {
            navigate('/login');
        } else {
            fetch(`${BASE_URL}user`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => {
                if (res.status === 401) {
                    navigate('/login');
                }
                return res.json();
            })
            .catch((error) => {
                console.log('houve um erro', error);    
                navigate('/login');
            });
        }
     }, []);
    return (
        <div>
             <JAlerta showError={authContext?.showError} close={authContext?.setShowError} messageError={authContext?.showMessageError} />   
        </div>
    );
};
