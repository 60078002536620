import React, { useState, useContext, useRef } from "react";
import { Form, Button, Container, Card, Alert } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";

const initial = {
    email: '',
    password: '',
}

const Login: React.FC = () => {
    const authContext = useContext(AuthContext);
    const [usuarioForm, setUsuarioForm] = useState(initial);
    const btnLogin = useRef<HTMLButtonElement>(null);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setUsuarioForm({...usuarioForm, [name]: value});
    }

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        if (authContext) {
            btnLogin.current?.setAttribute('disabled', 'disabled');

            await authContext.login(usuarioForm.email, usuarioForm.password);
            btnLogin.current?.removeAttribute('disabled');

        }
    }

    return (
        <div style={{ alignItems: 'center', backgroundColor: '#e9ecef', 'display': 'flex', 
            flexDirection: 'column', 'height': '100vh', justifyContent: 'center' }}>
        
        <Container className="justify-content-center" style={{ maxWidth: '400px',}}>
           
            <Alert variant="danger" show={authContext?.showError} 
                onClose={() => authContext?.changeShowError(false)} dismissible>
                <span>{authContext?.showMessageError}</span>
            </Alert>

            <Form onSubmit={handleLogin}>
                <Card style={{borderTop: '3px solid #007bff'}}>
                    <Card.Header className="py-3 text-center fw-bold card-outline-primary">
                        Informe suas credenciais
                    </Card.Header>
                    <Card.Body>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>E-mail:</Form.Label>
                                <Form.Control type="text" name="email" 
                                    value={usuarioForm.email} 
                                    onChange={handleChange}
                                    autoFocus
                                                                                
                                />
                                
                                {authContext?.errors.email && (
                                    <ul className="errorfield">
                                        {authContext?.errors.email.map((error, index) => (
                                            <li key={index} className="text-danger">{error}</li>
                                        ))}
                                    </ul>
                                )}
                               
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>Senha:</Form.Label>
                                <Form.Control type="password" value={usuarioForm.password} 
                                    name="password"  onChange={handleChange} />
                                {authContext?.errors.password && (
                                    <ul className="errorfield">
                                        {authContext?.errors.password.map((error, index) => (
                                            <li key={index} className="text-danger">{error}</li>
                                        ))}
                                    </ul>
                                )}                                            
                            </Form.Group>

                            <div className="d-flex justify-content-between">
                                <a className="text-decoration-none" href="/forgot-password">Esqueci minha senha</a>
                                <a className="text-decoration-none" href="/register">Registrar</a>
                            </div>
                    
                    </Card.Body>
                    <Card.Footer className="d-grid gap-2">
                        <Button variant="primary" type="submit" ref={btnLogin}>
                            <FontAwesomeIcon icon={faUserLock} title="Entrar"/> Entrar
                                
                        </Button>
                    </Card.Footer>
                </Card>
            </Form>
                
        </Container>
        </div>
    );
}

export default Login;