import { useEffect, useState, useContext} from "react";
import { ManifestoInterface as iData } from "../../interfaces/ManifestoInterface";
import  manifestoService   from "../../services/ManifestoService";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { JAlerta, JCaption, JCard, JCardBody, JConfirmDelete, JHeader, JPaginacao, JTable, JTBody, JTHead } from "../../components/Components";
import { AuthContext } from "../../contexts/AuthContext";

const initial = {
    id: "",
    ufini: "",
    uffim: "",
    nmdf: 0,
    chave: "",
    protocolo: "",
    modal: "",
    tptransp: "",
    dhemi: "",
    infocompl: "",
    dhcancel: "",
    dhencer: "",
    situacao: "",
};

export default function IndexManifesto() {
    const [dados, setDados] = useState<iData[]>([]);
    const [showModal, setShowModal] = useState(false); 
    const [showSpinner, setShowSpinner] = useState(true);
    const [selected, setSelected] = useState<iData>(initial);
    const [showError, setShowError] = useState(false);
    const [showMessageError, setShowMessageError] = useState('');
    const [search, setSearch] = useState('');
    const [meta, setMeta] = useState<any>(null)
    const [links, setLinks] = useState<any>(null)
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Insert') {
            navigate('/manifesto/create');
        }
    }
    
    const  searchFilter =  (page: string | null = null) =>  {
  
       setShowSpinner(true);
        
        manifestoService.getAllPaginate(search, page).then( response => {
            setDados(response.data);
            setLinks(response.links);
            setMeta(response.meta);
            setShowSpinner(false);
            
        }).catch(err => {
            setShowSpinner(false);
        });

    }

    const handleDelete = (id: string) => {
         
        manifestoService.delete(id).then( data => {
            setDados(dados.filter( data => data.id !== id));
            setShowModal(false);
            
        }).catch(err => {
            const error400 = [403,400]

            if (error400.includes(err.response.status)) {
                setShowModal(false);
                setShowError(true)
                setShowMessageError(err.response.data.detail)
            }
        });
    }


    const handlePageChange = (page: string | null = null) => {
        searchFilter(page)
    }

    const handleOpenModal = (conta: any) => {
        setSelected(conta);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setSelected(initial);
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown)
        searchFilter();

        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [search]);

    return (
        <>
            <JAlerta showError={showError} close={setShowError} messageError={showMessageError} />           
            <div className="row d-flex justify-content-between align-items-start g-2">
                <Col md>
                    <JCard className="shadow card-outline">
                        <JHeader  
                            urlCreate="/manifesto/create"
                            search={search}
                            links={links ?? {}}
                            meta={meta ?? {}}
                            setSearch={setSearch}
                            showSpinner={showSpinner}
                            permissionCreate={authContext?.checkPermission('manifesto_create')}
                            permissionSearch={authContext?.checkPermission('manifesto_search')}
                        />
                        <JCardBody>
                            <JCaption>
                                Lista de Manifestos
                                <JPaginacao 
                                    
                                    handlePageChange={handlePageChange} />
                            </JCaption>
                            <JTable >
                            
                                <JTHead>
                                    
                                    <tr>
                                        <th style={{ width: '5rem' }} className="text-center">Ações</th>
                                        <th style={{ width: '7rem' }}>
                                            Número
                                        </th>
                                        <th style={{ width: '11rem' }}>Data de Emissão</th>
                                        <th style={{ width: '5rem' }}>UF Ini.</th>
                                        <th style={{ width: '5rem' }}>UF Fim</th>
                                        <th  >Chave</th>
                                        <th style={{ width: '17rem' }} >Modal</th>

                                        <th style={{ width: '17rem' }} className="text-center">
                                            Situação
                                        </th>
                                        
                                    </tr>
                                </JTHead>
                                <JTBody>
                                    {dados?.map((data) => (
                                        <tr key={data.id}>
                                            <td className="text-center">
                                                <Link className="text-primary" to={`/manifesto/${data.id}`}>
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </Link>
                                                <button type="button" onClick={() => handleOpenModal(data)} 
                                                    className="text-danger ms-2" 
                                                    style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                </button>
                                            </td>
                                            <td>{data.nmdf}</td>
                                            <td>{data.dhemi}</td>
                                            <td>{data.ufini}</td>
                                            <td>{data.uffim}</td>
                                            <td>{data.chave}</td>
                                            <td>{data.modal}</td>
                                            <td className="text-center">{data.situacao}</td>
                                            
                                             
                                        </tr>
                                    ))}                           
                                </JTBody>
                            </JTable>
                        </JCardBody>
                    </JCard>
                </Col>
            </div>
            
            <JConfirmDelete 
                showModal={showModal} 
                handleCloseModal={handleCloseModal} 
                handleDelete={handleDelete} 
                selectedObject={selected?.nmdf} 
                id={selected?.id}
            />
        
        </>
    );
};

