import React, {useContext} from "react";
import Menu from "../components/Menu";
import { Container } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import './Layout.css';
import { AuthContext } from "../contexts/AuthContext";

const Layout: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const authContext = useContext(AuthContext);
    const empresa = authContext?.empresa;

    return (
        <>
        
        <Container fluid className="px-0">
            <Menu />
            <div className="p-2 mb-3">
                {children}

            </div>
            
        </Container>
            <footer className="fixed-bottom d-flex justify-content-between pb-1 pe-1 px-2 border-top border-secondary-subtle bg-body-tertiary"> 
                <div>
                    © 2024 - {new Date().getFullYear().toString()} Todos os direitos reservados.
                </div>
        
                {authContext?.empresas && (
                    <>
                        {authContext?.empresas.filter(e => e.id === empresa).map((empresa) => (
                            <div >
                                {empresa.nome} - {empresa.cnpj}
                                <Icon.Building className="ms-1 me-1" />
                            </div>
                        ))}
                    </>
                )}
                
            </footer>
            </>
    );
}

export default Layout;