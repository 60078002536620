import { useEffect, useState, useContext} from "react";
import { EstadoInterface as iData } from "../../interfaces/EstadoInterface";
import estadoService from "../../services/EstadoService";
import {  
    JCaption, 
    JCard, 
    JCardBody, 
    JHeader, 
    JPaginacao, 
    JTable, 
    JTBody, 
    JTHead, 
} from "../../components/Components";
import { AuthContext } from "../../contexts/AuthContext";

export default function IndexEstado() {
    const [dados, setDados] = useState<iData[]>([]);
    const [showSpinner, setShowSpinner] = useState(true);
    const [search, setSearch] = useState('');
    const [meta, setMeta] = useState<any>(null)
    const [links, setLinks] = useState<any>(null)
    const authContext = useContext(AuthContext);
    const  searchFilter =  (page: string | null = null) =>  {

        setShowSpinner(true);
        
        estadoService.getAllPaginate(search, page).then( response => {
            setDados(response.data);
            setLinks(response.links);
            setMeta(response.meta);
            setShowSpinner(false);
            
        }).catch(err => {
            setShowSpinner(false);
        });
    }

    const handlePageChange = (page: string | null = null) => {
        searchFilter(page)
    }

    useEffect(() => {
        searchFilter();
    }, [search]);

    return (
        <>
            <JCard className="shadow card-outline">
                <JHeader  
                    search={search}
                    setSearch={setSearch}
                    showSpinner={showSpinner}
                    permissionCreate={authContext?.checkPermission('estado_create')}
                    permissionSearch={authContext?.checkPermission('estado_search')}
                />
                <JCardBody>
                    <JCaption>
                        Lista de estados
                        <JPaginacao 
                            links={links ?? {}}
                            meta={meta ?? {}}
                            handlePageChange={handlePageChange} />
                    </JCaption>
                    <JTable>
                    
                        <JTHead>
                            <tr>
                                <th style={{ width: '7rem' }}>
                                   UF
                                </th>
                                <th>
                                   Nome
                                </th>
                                <th>
                                   Nome do país
                                </th>
                            </tr>
                        </JTHead>
                        <JTBody>
                            {dados?.map((data) => (
                                <tr key={data.id}>
                                    
                                    <td>{data.uf}</td>
                                    <td>{data.nome}</td>
                                    <td>{data?.pais?.nome}</td>
                                </tr>
                            ))}                           
                        </JTBody>
                    </JTable>
                </JCardBody>
            </JCard>
        </>
    );
};

